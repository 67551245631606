import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import fetchCurrentGold from "../fetch-current-gold";
import fetchUserAuctionBid from "../fetch-user-auction-bid";

import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @TODO - Run auction tx
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns The new amount of gold after bidding.
 */
const ClaimAuctionGold = async (
    provider: ethers.providers.Web3Provider
): Promise<OutputI<{
    fetchCurrentGoldResult: number,
    fetchHighest: number
}>> => {
    try {
        // Do auction stuff here...
        let evSacrificer = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);
        let tx = await evSacrificer.connect(await provider.getSigner()).claimBackAuctionGold(2);
        await tx.wait();

        // Fetching updated gold amount in wallet.
        const fetchCurrentGoldResult = await fetchCurrentGold(provider);        
        if(fetchCurrentGoldResult.err) throw new Error(fetchCurrentGoldResult.msg);
        if (fetchCurrentGoldResult.output === undefined) throw new Error("Current gold amount is undefined");        

        const fetchHighest = await fetchUserAuctionBid(provider);
        if(fetchHighest.err) throw new Error(fetchHighest.msg);
        if (fetchHighest.output === undefined) throw new Error("fetchHighest amount is undefined");


        return {err: false, output: {
            fetchCurrentGoldResult: fetchCurrentGoldResult.output,
            fetchHighest: fetchHighest.output,
        }};
    } catch (error) {
        return {err: true, msg: `claim-gold/index.ts::claimGold():: Failed to claim gold! || \n${error}`};
    }
}

export default ClaimAuctionGold;