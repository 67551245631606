import { NFT1 } from '../../assets/nft-images';
import OutputI from "../../interfaces/Output";

type FetchNftMetadataAPIResponse = {
    imageUrl: string
}

/**
 * @KASH
 * @TODO enter the base url for the metadata endpoint.
 * @param nftId - The id of the nft we need the image for.
 * @returns The url to the image of the nft.
 */
export const fetchImageByNftId = async (nftId: number): Promise<OutputI<string>> => {
    try {
        /**
         * @TODO Use this code to generate the imgURL from a base url.
         * START
         */
            const output = "https://eternalvikings.mypinata.cloud/ipfs/QmWxdwZZZSVZ3CjAEjW8bo65JRGWfssFhuaz4C1q79bXxo/" + nftId + (nftId === 1501 ? ".jpeg" : ".png");
        /**
         * @TODO Use this code to generate the imgURL from a base url.
         * END
         */
        /**
         * @TODO Use this code to parse the imgURL from metadata.
         * START
         */
        /** @TODO Insert metadata base url here. */
        // const baseUrl = "";
        // const res = await axios.get<FetchNftMetadataAPIResponse>(baseUrl + nftId);
        // if (res.status) throw new Error("Failed to fetch metadata! response: " + res.statusText);
        // const output = res.data.imageUrl;
        /**
         * @TODO Use this code to parse the imgURL from metadata.
         * END
         */
        //const output = NFT1; // temp output.
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `api/index.ts::fetchImageByNftId():: Failed to fetch the nft's image! || input: ${nftId} || \n${error}`};
    }
}